const mousemove = document.querySelector('.customization')
const first = document.querySelector('.mousemove1')
const middle = document.querySelector('.mousemove2')
const last = document.querySelector('.mousemove3')

const sFront = 250;
const sBack = 400;

mousemove?.addEventListener('mousemove', e => {
    const x = e.clientX;
    const y = e.clientY;


    first.style.transform = `translate(${x / sFront}%, ${y / sFront}%)`
    middle.style.transform = `translate(${x / sBack}%, ${y / sBack}%)`
    last.style.transform = `translate(${x / sFront}%, ${y / sFront}%)`
})