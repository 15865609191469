import React from 'react'
import { contents } from '../../globals/app-constants'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-responsive-modal/styles.css';

export const SignUpCarousel = () => {
    return (
        <div className='signup-carousel'>
            <Carousel
                autoPlay={true}
                showIndicators={false}
                infiniteLoop={true}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                interval={3000}
                className='signup-carousel-align'
            >
                <div className='text-start'>
                    <h5 className="fs-14 O-r"><span className="fs-20"><b>{contents.signupCarousel.heading1}</b></span></h5>
                    <p className="fs-18 O-r pt-2 letter-spacing-carousel">{contents.signupCarousel.context1}</p>
                </div>

                <div className='text-start'>
                    <h5 className="fs-14 O-r"><span className="fs-20"><b>{contents.signupCarousel.heading2}</b></span></h5>
                    <p className="fs-18 O-r pt-2 letter-spacing-carousel">{contents.signupCarousel.context2}</p>
                </div>

                <div className='text-start'>
                    <h5 className="fs-14 O-r"><span className="fs-20"><b>{contents.signupCarousel.heading3}</b></span></h5>
                    <p className="fs-18 O-r pt-2 letter-spacing-carousel">{contents.signupCarousel.context3}</p>
                </div>

                <div className='text-start'>
                    <h5 className="fs-14 O-r"><span className="fs-20"><b>{contents.signupCarousel.heading4}</b></span></h5>
                    <p className="fs-18 O-r pt-2 letter-spacing-carousel">{contents.signupCarousel.context4}</p>
                </div>
            </Carousel>
        </div>
    )
}