import { PATH } from '../globals/app-constants'
import { VIRAL_LOOP_URL } from '../utils';
import { useNavigate } from 'react-router-dom';

const ReferalPage = () => {
    const navigate = useNavigate();
    const localStoreageValue = localStorage.getItem(`${VIRAL_LOOP_URL}#userData`);
    const handleSignout = () => {
        if (localStoreageValue) {
            localStorage.clear(localStoreageValue, 'localStoreageValue');
            navigate('/');
            window.location.reload();
        }
    };

    return (
        <div className="referral_page">
            <div className='referral-page'>
                <div className="bg-white">
                    <div className='position-relative insta-height-align'>
                        <img src={PATH.img.ParallaxBg4} className='w-100 h-100 object-fit-cover' alt='images' />
                        <div className='viral-overlay'></div>
                        <div className='d-flex justify-content-center align-items-center w-100 h-100 content-align-viral-loop'>
                            <p className='O-sb fs-34 text-white text-center'>Win more rewards as you refer your friends</p>
                        </div>
                        {!localStoreageValue ? <></> :
                            <p className="O-b text-black text-center cursor-pointer d-flex align-items-center signout-align"
                                onClick={() => handleSignout()}><img src={PATH.img.LogoutIcon} className='signout-icon' /><b className='ps-lg-2 ps-1 d-none d-lg-block fs-13 text-white'>sign out</b></p>}
                    </div>

                    <div className="milestone-container py-5">
                        <p class="fs-24 O-b mb-5 text-center">Rewards Milestone</p>
                        <milestone-widget ucid={VIRAL_LOOP_URL}></milestone-widget>
                    </div>
                    <div className="row mx-auto m-0">
                        <div className="col-md-6 bg-gray p-0">
                            <img src={PATH.img.ViralLoopContentImg} className="w-100 object-fit-contain viral-loop-img-align" alt="viral-loop-img" />
                        </div>
                        <div className='col-md-6 px-3 px-lg-5 viral-loop-bg position-relative'>
                            <div className="d-flex flex-column h-100 gap-3 heading-alignment">
                                <form-widget ucid={VIRAL_LOOP_URL}></form-widget>
                            </div>
                        </div>
                    </div>
                    {/* ---- Footer start ---- */}
                    <div className="d-flex flex-column gap-24 footer-section-align footer-section-align-viral-loop">
                        <div className="footer-bg footer-viral-loop">
                            <div className="container mx-auto d-lg-flex justify-content-between">
                                <img src={PATH.img.LogoBlack} className="footer-logo-width d-block d-lg-inline mx-auto mx-lg-0 mb-4" alt="logo" />
                                <div className="d-flex flex-column gap-24 align-items-center align-items-lg-end">
                                    <div className="d-flex gap-24">
                                        <a href="" target="_blank"><img src={PATH.img.Facebook} className="cursor-pointer" alt="facebook" /></a>
                                        <a href="" target="_blank"><img src={PATH.img.Whatsapp} className="cursor-pointer" alt="whatsapp" /></a>
                                        <a href="https://www.instagram.com/worldofakal" target="_blank"><img src={PATH.img.Instagram} className="cursor-pointer insta-icon" alt="instagram" /></a>
                                        <a href="" target="_blank"><img src={PATH.img.LinkedIn} className="cursor-pointer" alt="linkedin" /></a>
                                    </div>
                                    <p className="fs-14 H-l text-center text-lg-end">Copyright @2024. All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ---- Footer end ---- */}

                </div >
            </div >

        </div >
    )
}

export default ReferalPage;