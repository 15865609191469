import React from "react";
import { S3BASE_URL_SUIT, SUIT_TRANSPARENT, convertAdminProductId, detectBrowser, detectBrowserBaseUrl } from "../../utils";
import SuitStyle from '../../globals/suit_style.json';


class SuitCanvas extends React.Component {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
        this.state = {
            selectedJSON: {
                style: SuitStyle['style'].child[0],
                fit: SuitStyle['fit'].child[0],
                pocket: SuitStyle['pocket'].child[0],
                breast_pocket: SuitStyle['breast_pocket'].child[0],
                lapels: SuitStyle['lapels'].child[0],
                lapels_width: SuitStyle['lapels_width'].child[0]
            },
            selectedCombination: {},
            maxHeight: 0
        };
    }

    static getDerivedStateFromProps(props, state) {
        let { selectedJSON } = state;
        let { productId, activeSection } = props || {};
        let currentChildMenu = localStorage?.getItem("canvas") ? JSON.parse(localStorage?.getItem("canvas")) : null;
        let basepath = S3BASE_URL_SUIT + '7079274250404/' + detectBrowserBaseUrl() + '/';

        let suitBaseAccentsPath = S3BASE_URL_SUIT + 'accents';

        let baseSuitInnerPath = suitBaseAccentsPath + '/6756353671332/custom/';

        if (productId !== 'undefined') {
            basepath = S3BASE_URL_SUIT + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/';
        }



        let selectedCombinationObj = {
            foldedSuitFit: basepath + 'style/normal_fit/slim/notch/folded/single_one_breasted.png',
            foldedPocket: basepath + 'transparent/transparent.png',
            foldedBreastPocket: basepath + 'transparent/transparent.png',
            foldedSleeve: basepath + 'sleeve/front/sleeve.png',
            foldedPant: basepath + 'pant/folded_pant.png',
            foldedNormalFit: basepath + 'fit/folded/normal_fit_ventless.png',
            foldedInnerBottom: basepath + 'bottom/folded/inner_bottom.png',
            foldedInnerSuit: basepath + 'bottom/folded/inner_suit.png',
            foldedCollar: basepath + 'collar/folded/other_collar.png',
            suitInner: baseSuitInnerPath + 'suit_interior.png',
            suitBottomInner: baseSuitInnerPath + 'suit_interior_bottom.png',
            foldedSuitButtons: suitBaseAccentsPath + '/buttons/folded/j8/single_one_button.png',
            foldedButtonThread: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_center/white.png',
            foldedButtonHole: suitBaseAccentsPath + '/threads/folded/single_1_button_thread/button_hole/white.png',
            foldedLapelsHole: suitBaseAccentsPath + 'threads/folded/notch_collar_hole/white.png',
        };
        if (activeSection !== 'fabric' && currentChildMenu && currentChildMenu[activeSection]) {
            selectedJSON = {
                ...selectedJSON,
                [activeSection]: currentChildMenu[activeSection]
            };
        }

        let foldedButtonThread = '';
        let foldedButtonHole = '';
        let foldedLapelsHole = '';
        Object.keys(selectedJSON).forEach((key) => {
            switch (key) {
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPocket: selectedJSON[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedJSON[key]?.folded_image_url,
                    };
                    break;
                case 'breast_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedBreastPocket: selectedJSON[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedJSON[key]?.folded_image_url,
                    };
                    break;
                case 'fit':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedNormalFit: selectedJSON[key]?.key === 'normal_fit' ? basepath + 'fit/folded/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT
                    };
                    break;
                case 'sleeve_buttons':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSleeve: basepath + selectedJSON[key]?.folded_image_url,
                    };
                    break;
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSuitFit: basepath + 'style/' + selectedJSON['fit']?.key + '/' + selectedJSON['lapels_width']?.key + '/' + selectedJSON['lapels']?.key + '/folded/' + selectedJSON[key]?.folded_image_url,
                        foldedCollar: basepath + 'collar/folded/other_collar.png',
                        foldedButtonThread: foldedButtonThread,
                        foldedButtonHole: foldedButtonHole,
                    };
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedCollar: basepath + 'collar/folded/other_collar.png',
                        suitInner: baseSuitInnerPath + 'suit_interior.png',
                        foldedLapelsHole: foldedLapelsHole,
                    };
                    break;
                default:
                    break;
            }
        });

        return {
            selectedJSON,
            selectedCombination: selectedCombinationObj,
        };
    }
    getImageDetails = (event) => {
        const img = event.target;
        if (img.complete && img.naturalHeight !== 0) {
            setTimeout(() => {
                this.handleImageLoad(img);
            }, 100);
        } else {
            img.addEventListener('load', () => setTimeout(() => {
                this.handleImageLoad(img);
            }, 100));
        }
    }

    handleImageLoad = (img) => {
        const height = img.offsetHeight;
        this.setState({ maxHeight: height })
    }
    render() {
        const { maxHeight } = this.state;
        const { selectedCombination } = this.state;
        const { foldedSuitFit, foldedPocket, foldedBreastPocket, foldedNormalFit, foldedSleeve,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitInner, suitBottomInner } = selectedCombination || {};

        return (
            <div className="pi-shirt-containers">
                <div className={`pi_container pi-suit pi_suit_cuff position-relative`} style={{ height: maxHeight }}>
                    <img className="custom_shirt folded_fit"
                        src={foldedNormalFit?.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={foldedCollar?.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_inner_suit"
                        src={foldedInnerSuit?.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_collar"
                        src={suitInner}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={foldedInnerBottom.replace('.png', detectBrowser())}
                        alt='shirt' />
                    <img className="custom_shirt folded_collar"
                        src={suitBottomInner}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={foldedSuitFit?.replace('.png', detectBrowser())}
                        alt='shirt' />
                    {/* <img className="custom_shirt folded_lapels"
                        src={foldedLapelsHole}
                        alt='shirt' />
                    <img className="custom_shirt folded_fit"
                        src={suitPocketsSquare}
                        alt='shirt' /> */}

                    <img className="custom_shirt folded_pleats"
                        src={foldedSleeve?.replace('.png', detectBrowser())}
                        alt='shirt'
                    />
                    <img className="custom_shirt folded_pocket"
                        src={foldedPocket?.replace('.png', detectBrowser())}
                        alt='shirt' />

                    <img className="custom_shirt folded_side_pocket"
                        onLoad={this.getImageDetails}
                        src={foldedBreastPocket?.replace('.png', detectBrowser())}
                        alt='shirt' />

                    {/* <img className="custom_shirt folded_hole"
                        src={foldedButtonHole}
                        alt='shirt' /> */}

                    {/* <img className="custom_shirt folded_button"
                        src={foldedSuitButtons}
                        alt='shirt' />

                    <img className="custom_shirt folded_thread"
                        src={foldedButtonThread}
                        alt='shirt' /> */}
                </div>

            </div>
        );
    }
}

export default SuitCanvas

