import React, { useState, useEffect, useRef } from "react";
import { PATH, contents, images } from "../../globals/app-constants";
import ButtonDesign from "../../component/button";
import ParallaxSlide from "../../component/parallax_slide";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../component/mousemove';
import { initialCall, INSTAGRAM_URL, VIRAL_LOOP_URL, INSTAGRAM_TOKEN } from "../../utils";
import { GET_SUIT_FABRICS } from "../../utils/graphQl";
import { Timer } from "./timer";
import SuitCanvas from "./canvas";
import SuitStyle from '../../globals/suit_style.json';
import LazyLoad from 'react-lazyload';
import { SignUpCarousel } from "./signup";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

let intialJSON = {
    style: SuitStyle['style'].child[0],
    fit: SuitStyle['fit'].child[0],
    pocket: SuitStyle['pocket'].child[0],
    breast_pocket: SuitStyle['breast_pocket'].child[0],
    lapels: SuitStyle['lapels'].child[0],
    lapels_width: SuitStyle['lapels_width'].child[0]
};
const Home = () => {

    const [fixHeader, setFixHeader] = useState(false);
    const [activeSection, setActiveSection] = useState("fabric");
    const [fabrics, setData] = useState();
    const [currentFabrics, setCurrentFabrics] = useState();
    const [currentChildMenu, setCurrentChildMenu] = useState(intialJSON);
    const [showDetails, setShowDetails] = useState(false);
    const [latestPost, setLatestPost] = useState(null);
    const targetRef = useRef(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    let currentTimeout = useRef(null);
    const navigate = useNavigate()
    const localStoreageValue = localStorage.getItem(`${VIRAL_LOOP_URL}#userData`);
    useEffect(() => {
        const observerCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Set a timeout to show the popup after 10 seconds
                    currentTimeout.current = setTimeout(() => {
                        if (localStoreageValue) {
                            navigate('/referralpage')
                        } else {
                            setIsPopupVisible(true)
                        }

                    }, 7000);
                } else {
                    // Clear the timeout if the element is no longer intersecting
                    clearTimeout(currentTimeout.current);
                }
            });
        };

        const observerOptions = {
            root: null, // Use the viewport as the root
            rootMargin: '0px',
            threshold: 0.1 // Trigger when 10% of the target is visible
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        // Cleanup function to unobserve the target element and clear the timeout when the component unmounts
        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
            clearTimeout(currentTimeout.current);
        };
    }, []);
    const reservepage = () => {
        if (localStoreageValue) {
            navigate('/referralpage')
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", function () {
            if (window.scrollY >= 200) {
                setFixHeader(true);
            } else setFixHeader(false);
        });
    }, []);

    const resetCanvas = () => {
        const { products: { edges } } = fabrics?.collectionByHandle || {};
        setCurrentFabrics(edges && edges[0]?.node);
        setCurrentChildMenu(intialJSON)
        localStorage.setItem("canvas", JSON.stringify(intialJSON));

    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    useEffect(() => {
        let query = GET_SUIT_FABRICS();

        const fetchData = async () => {
            try {
                const response = await initialCall(query);
                setData(response?.data);
                const { products: { edges } } = response?.data?.collectionByHandle || {};

                setCurrentFabrics(edges && edges[0]?.node);
            } catch (error) {
                console.log("Error");
            } finally {
                console.log("completed");
                // setLoading(false);
            }
        };

        fetchData();
    }, []);

    const changeSuitStyle = (key, data) => {
        let obj = {
            [key]: data,
        };
        let state = {
            ...currentChildMenu,
            [key]: data,
        };
        localStorage.setItem("canvas", JSON.stringify(obj));
        setCurrentChildMenu(state);
    };

    const changeSuitMainStyle = (key) => {
        setActiveSection(key);
        // let obj = {}
        // let isExistingChildMenu = localStorage.getItem("canvas") ? JSON.parse(localStorage.getItem("canvas")) : null
        // if (isExistingChildMenu) {
        //     obj = {
        //         ...isExistingChildMenu,
        //     }
        // } else {
        //     obj = {
        //         ...currentChildMenu,
        //         [key]: SuitStyle[key]?.child[0]
        //     }
        // }
        // localStorage.setItem("canvas", JSON.stringify(obj))
        // setCurrentChildMenu(obj)
    };
    const handleClick = () => {
        setShowDetails(true)
    }
    const handleSignout = () => {
        if (localStoreageValue) {
            localStorage.clear(localStoreageValue, 'localStoreageValue');
            window.location.reload();
        }
    };
    const navInstagram = () => {
        window.open(INSTAGRAM_URL, "_blank")
    }


    useEffect(() => {
        const fetchLatestPost = async () => {
            try {
                const response = await fetch(
                    `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,thumbnail_url,caption,timestamp&access_token=${INSTAGRAM_TOKEN}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                // Assuming the latest post is the first item in the data array
                const recentPosts = data.data.slice(0, 5);
                setLatestPost(recentPosts);
            } catch (error) {
                console.error("Error fetching latest post:", error);
            }
        };
        fetchLatestPost();
    }, []);

    const sideMenu = Object.keys(SuitStyle).map((key) => (SuitStyle[key]));
    const childSection = sideMenu?.find((obj) => obj?.key === activeSection);
    return (
        <React.Fragment>
            <div className="prelaunch">
                {/* ---- banner start ---- */}
                <div className="vh-100 position-relative">
                    {/* <video src={PATH.video.Banner_video} alt="banner-video" className="w-100 h-100 object-fit-cover" autoPlay={true} loop={true} muted playsInline>
                    </video> */}
                    <LazyLoad className="vh-100 d-none d-md-block w-100 h-100 object-fit-cover">
                        <video alt="banner-video" className="w-100 h-100 object-fit-cover" autoPlay={true} loop={true} muted playsInline>
                            <source src='https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/prelaunch/banner_web.mp4' type="video/mp4" />
                        </video>
                    </LazyLoad>
                    <LazyLoad className="vh-100 d-block d-md-none w-100 h-100 object-fit-cover">
                        <video alt="banner-video" className="w-100 h-100 object-fit-cover" autoPlay={true} loop={true} muted playsInline>
                            <source src='https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/prelaunch/banner_mobile.mp4' type="video/mp4" />
                        </video>
                    </LazyLoad>
                    <div className="position-absolute bg-black opacity-25 top-0 start-0 w-100 h-100"></div>
                    <div className="position-absolute top-0 start-0 d-flex justify-content-center align-items-center w-100 h-100">
                        <img src={PATH.img.Logo} className="logo-width text-white" alt="logo" />
                    </div>
                    <div>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-end container gap-8 Referral-btn cursor-pointer" >
                                {/* <img src={PATH.img.ReferralIcon} className="referral-icon-bg" alt="referral-icon" /> */}
                                <ButtonDesign
                                    value={contents.home.refer_friend}
                                    className={'bg-transparent text-white px-2 refer-friend-btn'}
                                    action={localStoreageValue ? "" : `modal`}
                                    targetdata={'#referralpopup'}
                                    arrow
                                    arrowPath={PATH.img.ArrowWhite}
                                    handleClick={reservepage}
                                //    {localStoreageValue ? handleClick={() => handleClick()} : ''}
                                />
                            </div>
                        </div>
                        <div className={`modal fade ${isPopupVisible ? 'show' : ''}`} id="referralpopup" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: isPopupVisible ? "block" : '' }}>
                            <div className="modal-dialog modal-fullscreen position-relative">
                                <div className="modal-content">
                                    <div className="modal-header border-0 p-0">
                                        <button type="button" className="btn-close position-absolute close-modal"
                                            data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsPopupVisible(false)}></button>
                                    </div>
                                    <div className="modal-body d-flex flex-column justify-content-center p-0 border-0 overflow-align overflow-hidden">
                                        <div className="row d-flex flex-lg-row flex-column-reverse m-0 h-100 position-relative">
                                            <div className="col-lg-7 p-0 h-100 position-relative">
                                                <img src={PATH.img.ParallaxBg4} className="w-100 h-100 object-fit-cover d-none d-lg-block" alt="sign-up-img" />
                                                <img src={PATH.img.SignupImgMobile} className="w-100 h-100 object-fit-cover d-block d-lg-none" alt="sign-up-img" />
                                                <SignUpCarousel />
                                            </div>
                                            <div className="col-lg-5 d-flex flex-column justify-content-lg-center px-lg-5 px-3 py-lg-5 py-3 form-mobile bg-white">
                                                <form-widget ucid='uzlrJyfAmikmkGiWYLGGOMCBGpI'></form-widget>
                                                {!localStoreageValue ? <p className="fs-16 O-b text-gray text-center cursor-pointer" data-bs-dismiss="modal">{contents.home.no_thanks}</p> :
                                                    <p className="fs-18 O-b text-black text-center cursor-pointer"
                                                        onClick={() => handleSignout()}
                                                        data-bs-dismiss="modal"><b>{contents.home.sign_out}</b></p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${fixHeader ? 'position-fixed bottom-0 start-0 bg-white fixed-bottom w-100 bottom-align-fixed ' : 'position-absolute bottom-0 start-0 end-0 bottom-align'}`}>
                        <div className={`text-white d-flex justify-content-center align-items-center container mx-auto fixed-bottom-gap`}>
                            <Timer
                                fixHeader={fixHeader}
                            />
                            <div className={`${fixHeader ? 'border-dark' : 'border-white'} border-start line-height`}></div>
                            <div className="d-flex align-items-center gap-5">
                                <p className={`${fixHeader ? 'text-dark' : 'text-white'} H-l fs-14 d-none d-lg-block`}><span className="fs-18"><b>{contents.home.banner_footer}</b></span><span className="fs-14">{contents.home.banner_footer_sub}</span></p>
                                <ButtonDesign
                                    value={contents.home.reserve_now}
                                    action={localStoreageValue ? "" : `modal`}
                                    targetdata={'#referralpopup'}
                                    handleClick={reservepage}
                                />
                                {/* <div className="d-flex align-items-center gap-3">
                                    <div className={`${fixHeader ? 'text-dark' : 'text-white'} w-15`}><img src={`${fixHeader ? PATH.img.WorldWide : PATH.img.WorldWideWhite}`} className="w-70 text-white" alt="world-wide" /> :</div>
                                    <select className={`form-select ${fixHeader ? 'form-select-black text-dark' : 'form-select-white-arrow text-white'} form-select-lg language-select`} aria-label="Small select example">
                                        <option className="text-dark" selected>Select the language</option>
                                        <option className="text-dark" value="1">English</option>
                                        <option className="text-dark" value="1">Thai</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* ---- banner end ---- */}
                {/* ---- brand start ---- */}
                <div className="brand-section px-24">
                    <h1 className="H-cb text-max-width mx-auto text-uppercase mx-auto text-center">{contents.home.section_para}</h1>
                </div>
                <div className="row m-0">
                    <div className="col-lg-6 p-0">
                        <div className="img-height border-design p-0 h-100 overflow-hidden">
                            <LazyLoad className="h-100" offset={100}>
                                <img src={PATH.img.ModelMan} className="w-100 h-100 object-fit-cover left-model" alt="model-pic" />
                            </LazyLoad>
                        </div>
                    </div>
                    <div className="col-lg-6 p-0">
                        <div className="img-height p-0 h-100 position-relative overflow-hidden">
                            <LazyLoad className="h-100" offset={100}>
                                <img src={PATH.img.ModelMan2} className="w-100 h-100 object-fit-cover right-model" alt="model-pic" />
                            </LazyLoad>
                            <p className="fs-16 H-cb text-white text-spacing text-uppercase d-none d-lg-block">{contents.home.model_text}</p>
                        </div>
                    </div>
                </div>
                <div className="vh-100 p-0">
                    <LazyLoad className="vh-100 d-none d-md-block w-100 h-100 object-fit-cover" offset={100}>
                        <video className="w-100 h-100 object-fit-cover" autoPlay={true} loop={true} muted playsInline>
                            <source src='https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/prelaunch/video_web.mp4' type="video/mp4" />
                        </video>
                    </LazyLoad>
                    <LazyLoad className="vh-100 d-block d-md-none w-100 h-100 object-fit-cover" offset={100}>
                        <video className="w-100 h-100 object-fit-cover" autoPlay={true} loop={true} muted playsInline>
                            <source src='https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/prelaunch/video-mobile-update.mp4' type="video/mp4" />
                        </video>
                    </LazyLoad>
                </div>
                {/* ---- brand end ---- */}
                {/* ---- customization demo start ---- */}
                <div className="">
                    <div className="container mx-auto py-44">
                        <div className="d-flex flex-column gap-24">
                            <p className="fs-44 H-cb text-uppercase">{contents.home.Details_heading}</p>
                            <p className="demo-text H-l"><span className="fs-18"><b>{contents.home.coming_soon}</b></span><span className="fs-14">{contents.home.coming_soon_subtext}</span></p>
                            <ButtonDesign
                                arrow
                                value={contents.home.expore_btn}
                                arrowPath={PATH.img.Arrow}
                                className={'bg-transprent'}
                                action={'modal'}
                                targetdata={'#customizepopup'}
                                handleClick={handleClick}
                            />
                            <div class="modal fade modal-customize" id="customizepopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-fullscreen overflow-x-hidden">
                                    <div class="modal-content">
                                        {
                                            showDetails &&
                                            <div class="modal-body py-0 customize-scroll-hidden custom-popup">
                                                <div className="row p-0">
                                                    <div className="col-xxl-3 d-none d-xxl-block custom-popup mobile-section pt-70" style={{ flexGrow: 1 }}>
                                                        <div className="d-flex flex-column gap-24 p-4">
                                                            <p className="fs-24 H-cb d-flex align-items-center gap-12">{contents.home.fabric_details}</p>
                                                            <p className="fs-14 H-l">{currentFabrics?.title}</p>
                                                            <div className="fabric-img">
                                                                <img src={currentFabrics?.images?.edges[0]?.node?.src} className="w-100 h-100 object-fit-cover" alt="fabric" />
                                                            </div>
                                                            <p className="fs-24 H-cb">{contents.home.description}</p>
                                                            <p className="fs-14 H-l">{currentFabrics?.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-5 col-xl-7 custom-popup d-flex flex-column gap-16 rendering-align position-relative">
                                                        <div className="mobile-section-change">
                                                            <div className="canvas-section position-relative">
                                                                <div className="d-flex d-lg-none gap-8 px-4 px-lg-0 pt-3 pt-lg-0">
                                                                    <img src={PATH.img.Arrow} data-bs-dismiss="modal" className="arrow-rotate cursor-pointer" alt="arrow" />
                                                                    <button className="btn H-cb custom-suit-align cursor-pointer" type="button" onClick={() => setShowDetails(false)}>{contents.home.suit_heading}</button>
                                                                </div>
                                                                <div className="reset-icon">
                                                                    <button className="btn fabric-mobile-icon flex-column align-items-center" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                                                        <div className="fabric-icon-align-canvas">
                                                                            <img src={PATH.img.FabricIcon} className="bg-white fabric-icon" alt="fabric-icon" />
                                                                        </div>
                                                                        <p className="H-l fs-10 pt-2 d-none d-md-block">{contents.home.fabric}<br />{contents.home.details}</p>
                                                                    </button>
                                                                    <button className="btn" type="button"
                                                                        onClick={() => resetCanvas()}
                                                                        aria-controls="offcanvasExample">
                                                                        <img src={PATH.img.Reset} className="bg-white reset-icon-align" alt="fabric-icon" />
                                                                        <p className="H-l fs-11 pt-2 d-none d-md-block">{contents.home.reset}</p>
                                                                    </button>
                                                                </div>
                                                                <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                                                                    <div class="offcanvas-body position-relative px-4">
                                                                        <button type="button" class="btn-close position-absolute close-modal close-canvas" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                                        <div className="d-flex flex-column gap-24 px-0 px-md-4 py-4">
                                                                            <p className="fs-24 H-cb d-flex align-items-center gap-12"><img src={PATH.img.FabricIcon} className="fabric-icon-canvas" alt="fabric-icon" />{contents.home.fabric_details}</p>
                                                                            <p className="fs-14 H-l">{currentFabrics?.title}</p>
                                                                            <div className="fabric-img">
                                                                                <img src={currentFabrics?.images?.edges[0]?.node?.src} className="w-100 h-100 object-fit-cover" alt="fabric" />
                                                                            </div>
                                                                            <p className="fs-24 H-cb">{contents.home.description}</p>
                                                                            <p className="fs-14 H-l">{currentFabrics?.description}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <SuitCanvas
                                                                    productId={currentFabrics?.id}
                                                                    currentChildMenu={currentChildMenu}
                                                                    activeSection={activeSection}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-4 col-xl-5 d-flex flex-column gap-16 p-0 p-lg-4 style-height-align">
                                                        <div className="d-none d-lg-flex gap-8 px-4 px-lg-0 pt-3 pt-lg-0">
                                                            <img src={PATH.img.Arrow} data-bs-dismiss="modal" className="arrow-rotate cursor-pointer" alt="arrow" />
                                                            <button className="btn H-cb custom-suit-align cursor-pointer"
                                                                type="button" onClick={() => setShowDetails(false)}>{contents.home.suit_heading}</button>
                                                        </div>
                                                        <p className="fs-14 H-l px-4 p-lg-0 d-none d-lg-block">{contents.home.custom_text}</p>
                                                        <div className="mobile-section-change">
                                                            <div className="d-flex flex-column gap-24 canvas-style-scroll">
                                                            </div>

                                                            <div className="p-4 p-lg-0">
                                                                <ul className="d-flex flex-lg-wrap fabric-section-scroll gap-16 list-unstyled custom-tab">
                                                                    <li className={`fs-18 H-l py-2 cursor-pointer ${activeSection === 'fabric' ? 'active' : ''}`}
                                                                        onClick={() => setActiveSection('fabric')}>{contents.home.fabric}</li>
                                                                    {
                                                                        sideMenu?.map((obj, index) => {
                                                                            return (
                                                                                <li onClick={() => changeSuitMainStyle(obj?.title?.toLowerCase())}
                                                                                    className={`fs-18 cursor-pointer H-l py-2 text-center ${activeSection === obj?.key ? 'active' : ''}`} key={index}>{obj?.title?.toLowerCase()}</li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                                <div className="d-flex flex-lg-wrap align-items-start fabric-section-scroll fabric-style-align" >
                                                                    {
                                                                        activeSection === 'fabric' && fabrics?.collectionByHandle?.products?.edges?.length > 0 &&
                                                                        fabrics?.collectionByHandle?.products?.edges?.map((obj, index) => {
                                                                            const { images: { edges }, title } = obj?.node || {};
                                                                            const productImage = edges && edges[0]?.node?.src;
                                                                            return (
                                                                                <div className="d-flex flex-column align-items-center gap-8 cursor-pointer canvas-style-align active-style" key={index} onClick={() => setCurrentFabrics(obj?.node)}>
                                                                                    <img src={productImage} className="style-align" alt="collar-syle" />
                                                                                    <div className="position-relative">
                                                                                        <p className="fs-14 H-l title-text text-center">{title?.toLowerCase()}</p>
                                                                                        <p className="fs-14 H-l fabric-fullname p-2 position-absolute text-uppercase">{title?.toLowerCase()}</p>
                                                                                    </div>
                                                                                    {
                                                                                        obj?.node?.id === currentFabrics?.id && <div className="active-dot"></div>
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                    {
                                                                        childSection?.child?.length > 0 &&
                                                                        childSection?.child?.map((obj, index) => {
                                                                            return (
                                                                                <div className="d-flex flex-column align-items-center gap-8 cursor-pointer canvas-style-align active-style"
                                                                                    key={index} onClick={() => changeSuitStyle(activeSection, obj)}>
                                                                                    <img src={images('./' + obj.icon)} className="style-align" alt="collar-syle" />
                                                                                    <div className="position-relative">
                                                                                        <p className="fs-14 H-l title-text text-center">{obj?.title?.toLowerCase()}</p>
                                                                                        <p className="fs-14 H-l fabric-fullname p-2 position-absolute text-uppercase">{obj?.title?.toLowerCase()}</p>

                                                                                    </div>
                                                                                    {currentChildMenu && currentChildMenu[activeSection]?.key === obj?.key &&
                                                                                        <div className="active-dot"></div>}

                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row customization-align align-items-center">
                            <div className="mousemove2 p-0">
                                <LazyLoad className="h-100" offset={100}>
                                    <video alt="demo-video" className="w-100 h-100 object-fit-cover d-none d-lg-block" autoPlay={true} loop={true} muted playsInline>
                                        <source src={"https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/prelaunch/gif_web_update.mp4"} type="video/mp4" />
                                    </video>
                                    <video alt="demo-video" className="w-100 h-100 object-fit-cover d-block d-lg-none" autoPlay={true} loop={true} muted playsInline>
                                        <source src={"https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/prelaunch/gif_mobile_update.mp4"} type="video/mp4" />
                                    </video>
                                </LazyLoad>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---- customization demo end ---- */}
                {/* parallax start ---- */}
                <div className="position-relative">
                    <ParallaxSlide
                        imgPath={PATH.img.ParallaxBg1}
                        Heading={contents.parallaxContents.heading1}
                        Content={contents.parallaxContents.context1}
                        slideOrder={'z-n1'}
                        overlayOrder={'z-0'}
                        textOverlay={'z-1'}
                    />
                    <ParallaxSlide
                        imgPath={PATH.img.ParallaxBg3}
                        Heading={contents.parallaxContents.heading2}
                        Content={contents.parallaxContents.context2}
                        slideOrder={'z-5'}
                        overlayOrder={'z-6'}
                        textOverlay={'z-7'}
                    />
                    <ParallaxSlide
                        imgPath={PATH.img.ParallaxBg4}
                        Heading={contents.parallaxContents.heading3}
                        Content={contents.parallaxContents.context3}
                        slideOrder={'z-8'}
                        overlayOrder={'z-9'}
                        textOverlay={'z-10'}
                    />
                </div>
                {/* ---- parallax end ---- */}

                {/* ---- gallery section ---- */}
                <div>
                    <div className="container mx-auto d-lg-flex gap-180 bg-white about-akal align-items-center">
                        <p className="text-uppercase fs-64 H-cb text-align-akal">
                            {contents.home.about}
                            {contents.home.akal}
                        </p>
                        <div className="d-flex flex-column justify-content-center gap-24">
                            <p className="fs-14 H-l line-align">{contents.home.about_akal_content1}</p>
                            <p className="fs-14 H-l line-align">{contents.home.about_akal_content2}</p>
                            <p className="fs-14 H-l line-align">{contents.home.about_akal_content3}</p>
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-44 container mx-auto gallery-section-align" id="create-account">
                        <div><img src={PATH.img.Instagram} className="" alt="instagram" /></div>
                        <Carousel responsive={responsive}>
                            {latestPost?.length > 0 && latestPost?.map((post, index) => {
                                return (
                                    <div key={index} className="h-600 border border-bottom-0">
                                        <p className="fs-14 H-l py-3 text-center caption-length">{post.caption || "--"}</p>
                                        {post?.media_type === "VIDEO" ?
                                            <video src={post?.media_url} className="w-100 h-100 object-fit-cover" autoPlay loop={true} muted playsInline></video>
                                            : <img className="w-100 h-100 object-fit-cover" src={post.media_url} alt="Instagram Post" />}

                                    </div>
                                )
                            })}
                        </Carousel>
                        <div className="d-flex justify-content-end">
                            <ButtonDesign
                                arrow
                                value={'View Official Instagram'}
                                imgRotate={'imgRotateDesign'}
                                arrowPath={PATH.img.Arrow}
                                className={'secondary-btn'}
                                handleClick={() => navInstagram()}
                            />
                        </div>
                    </div>
                </div>
                {/* ---- gallery section end ---- */}
                {/* ---- Footer start ---- */}
                <div className="d-flex flex-column gap-24 footer-section-align" id="create-account">
                    <div className="footer-bg" ref={targetRef}>
                        <div className="container mx-auto d-lg-flex justify-content-between">
                            <img src={PATH.img.LogoBlack} className="footer-logo-width d-block d-lg-inline mx-auto mx-lg-0 mb-4" alt="logo" />
                            <div className="d-flex flex-column gap-24 align-items-center align-items-lg-end">
                                <div className="d-flex gap-24">
                                    <a href="" target="_blank"><img src={PATH.img.Facebook} className="cursor-pointer" alt="facebook" /></a>
                                    <a href="" target="_blank"><img src={PATH.img.Whatsapp} className="cursor-pointer" alt="whatsapp" /></a>
                                    <a href="https://www.instagram.com/worldofakal" target="_blank"><img src={PATH.img.Instagram} className="cursor-pointer insta-icon" alt="instagram" /></a>
                                    <a href="" target="_blank"><img src={PATH.img.LinkedIn} className="cursor-pointer" alt="linkedin" /></a>
                                </div>
                                <p className="fs-14 H-l text-center text-lg-end">{contents.home.copyright1} @{moment().format('yyyy')}. {contents.home.copyright2}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ---- Footer end ---- */}
            </div >
        </React.Fragment >
    );
};
export default Home;