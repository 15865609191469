import { VIRAL_LOOP_URL } from "../utils";

const InvitePage = () => {


    return (
        <div>
            <form-widget ucid={VIRAL_LOOP_URL} mode='invitee'></form-widget>

        </div>
    )
}

export default InvitePage; 