import React from "react";
import LazyLoad from 'react-lazyload';

const ParallaxSlide = ({ imgPath, Heading, Content, slideOrder, overlayOrder, textOverlay }) => {
    return (
        <React.Fragment>
            <div className={`vh-100 p-0 position-sticky top-0 ${slideOrder}`}>
                <div className="position-relative w-100 h-100 d-flex justify-content-center">
                    <LazyLoad className="w-100 h-100" offset={100}>
                        <img src={imgPath} className="w-100 h-100 object-fit-cover parallax-img" alt="parallax-img" />
                    </LazyLoad>
                    <div className={`bg-black opacity-20 position-absolute w-100 h-100 top-0 start-0 ${overlayOrder}`}></div>
                    <div className={`position-absolute container mx-auto text-white top-40 px-0 ${textOverlay}`}>
                        <div className="text-align px-24">
                            <p className="fs-44 H-cb text-uppercase">{Heading}</p>
                            <p className="H-l pt-4 max-w-xl">{Content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default ParallaxSlide;