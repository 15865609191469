import axios from 'axios'
const { detect } = require('detect-browser');
const browser = detect();

export const toTitleCase = (str) => {
    return str.toLowerCase()?.split(' ')?.map(word => {
        return word?.charAt(0)?.toUpperCase() + word?.slice(1);
    }).join(' ');
}

export const headers = {
    'X-Shopify-Storefront-Access-Token': `3da7733ea17e0010bcc22d4100d72bbc`,
    'Content-Type': 'application/json'
}
const BASE_URL = "https://paulsfashion.myshopify.com/api/2020-10/graphql.json";


export const initialCall = (query) => {
    return axios.post(BASE_URL, {
        query: query,
    }, {
        headers: headers
    }).then((response) => {
        return response?.data
    }).catch((error) => {
        throw error
    })
}

export const detectBrowser = () => {
    if (browser && browser.name === 'safari') {
        return '.png'
    } else {
        return '.webp'
    }
}

export const convertAdminProductId = (id) => {
    if (id) {
        let parts = id.split('/');
        let number = parts[parts.length - 1];
        return `${number}/`
    }
}

export const detectBrowserBaseUrl = () => {
    if (browser && browser.name === 'safari') {
        return 'png'
    } else {
        return 'webp'
    }
}
export const S3BASE_URL_SUIT = 'https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/canvas/blazer/'
export const SUIT_TRANSPARENT = 'transparent/transparent.png'
export const INSTAGRAM_URL = 'https://www.instagram.com/worldofakal'

export const VIRAL_LOOP_URL = 'uzlrJyfAmikmkGiWYLGGOMCBGpI'

export const INSTAGRAM_TOKEN = 'IGQWROdkxvUUV3cXJrQ0w2a0pEY2JEYjAtT2JsNF9XQWc4dU9FWmJ4S2JZAVWJCYmJCVEdzWEd6a3ZAkY1hUNWMyTTlxZAGZAPVWVSb1RSX1BnM0N2NFh4WTREUlNSbVlPbEFkM3ZAaVzFYdlFrY21aTnVZAdVBsQlhXMW8ZD'

