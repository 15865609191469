import Logo from '../assets/images/logo.svg'
import LogoBlack from '../assets/images/AKAL-Logo.svg'
import ModelMan from '../assets/images/model-1.jpg'
import ModelMan2 from '../assets/images/model-2.jpg'
import ParallaxBg1 from '../assets/images/parallax-1.jpg'
import ParallaxBg2 from '../assets/images/parallax-2.jpg'
import ParallaxBg3 from '../assets/images/parallax-3.jpg'
import ParallaxBg4 from '../assets/images/parallax-4.jpg'
import Arrow from '../assets/images/arrow.svg'
import ArrowWhite from '../assets/images/arrowWhite.svg'
import CollarStyle1 from '../assets/images/collar-style1.svg'
import CollarStyle2 from '../assets/images/collar-style2.svg'
import CollarStyle3 from '../assets/images/collar-style3.svg'
import Instagram from '../assets/images/instagram.svg'
import Facebook from '../assets/images/facebook.svg'
import Whatsapp from '../assets/images/whatsapp.svg'
import LinkedIn from '../assets/images/linked-in.svg'
import FabricIcon from '../assets/images/fabric-icon.svg'
import Reset from '../assets/images/reset-icon.svg'
import SignupImg from '../assets/images/signup-img.png'
import SignupImgMobile from '../assets/images/signup-mobile.jpg'
import LogoutIcon from '../assets/images/logout-icon.svg'
import GoBack from '../assets/images/go-back.svg'
import ViralLoopContentImg from '../assets/images/viral-loop-img.png'
import WorldWide from '../assets/images/world-wide.svg'
import WorldWideWhite from '../assets/images/world-wide-white.svg'

export const PATH = {
    img: {
        Logo, LogoBlack, ModelMan, ModelMan2, ParallaxBg1, ParallaxBg2, ParallaxBg3, ParallaxBg4, Arrow,
        CollarStyle1, CollarStyle2, CollarStyle3,
        Instagram, Facebook, Whatsapp,
        LinkedIn, ArrowWhite, FabricIcon, Reset, SignupImg, SignupImgMobile,
        LogoutIcon, GoBack, ViralLoopContentImg, WorldWide, WorldWideWhite,
    },
}

export const images = require.context('../assets', true);

export const contents = {
    home: {
        refer_friend: "refer a friend",
        banner_footer: "Be First.Be Bold.Be AKAL -",
        banner_footer_sub: "Reserve your spot at the forefront of fashion. Unlock exclusive early access and special benefits today.",
        reserve_now: 'reserve now',
        section_para: 'In the fabric of time, AKAL weaves the future with timeless craftsmanship.',
        model_text: 'our excellent creation',
        Details_heading: 'Details',
        coming_soon: 'Coming Soon:',
        coming_soon_subtext: 'Our suit customizer — where your style meets our craft. Effortlessly unique, distinctly yours.',
        expore_btn: 'explore',
        fabric_details: 'Fabric details',
        description: 'Description',
        suit_heading: 'suit',
        fabric: 'fabric',
        details: 'details',
        reset: 'reset',
        custom_text: 'you can customize your suit',
        about: 'About',
        akal: 'akal',
        about_akal_content1: "AKAL emerged with a bold vision: to challenge fashion's outdated norms.Traditional tailoring often felt exclusive and stifling, lacking room for genuine expression.Sensing an opportunity for change, we set out to redefine the fashion experience.",
        about_akal_content2: "At AKAL, we're more than creators of garments; we're cultivators of community. Our focus is on fostering connection, expression, and authenticity. Style isn't just about appearance; it's about telling your unique story.",
        about_akal_content3: "Rooted in resilience and innovation, we transcend fleeting trends to stand for timeless elegance and forward-thinking vision. Join us in rewriting the rules of style, where individuality takes center stage and sophistication knows no bounds.",
        copyright1: "Copyright",
        copyright2: "All Rights Reserved.",
        no_thanks: 'No thanks',
        sign_out: 'sign out',
    },
    Timer: {
        D: 'D',
        H: 'H',
        M: 'M',
        S: 'S',
        Days: 'ays',
        Hours: 'ours',
        Mints: 'inutes',
        Secs: 'econds',
    },
    parallaxContents: {
        heading1: 'Our Story',
        context1: "AKAL redefines modern menswear, blending timeless tradition with innovation. Each piece celebrates individuality, crafted with a commitment to quality and craftsmanship. From Bangkok to global fashion capitals, AKAL is more than a brand; it's a lifestyle. Join us in weaving the future of fashion, one bespoke piece at a time.",
        heading2: 'TAILORED FOR IMPACT',
        context2: "From our choice of sustainable fabrics to our meticulous attention to detail, every stitch in our garments reflects our commitment to a more sustainable future. We're breaking away from the fast fashion model, focusing instead on creating timeless pieces that endure beyond trends.Through upcycling and restyling garments, we're not just making clothes; we're crafting stories that last a lifetime.",
        heading3: 'BEYOND FASHION',
        context3: "AKAL transcends the conventional boundaries of fashion. We are dedicated to building a community that values authenticity, creativity, and connection. We strive to cultivate a space where personal stories and experiences are woven into the fabric of our brand. By joining us, you become part of a collective journey towards a more inclusive and expressive fashion landscape, where every individual’s style tells a unique story."
    },
    signupCarousel: {
        heading1: "Exclusive Early Access:",
        context1: "Jump the queue. Be the first to uncover our newest collections and bespoke services—way before anyone else even knows they exist.",
        heading2: "Special Rewards:",
        context2: "Score unique perks and serious bragging rights just for joining us and bringing your friends along. It’s a win-win for your wardrobe and your social circle.",
        heading3: "Influence Future Designs:",
        context3: "Got opinions? We’re all ears. Your feedback shapes our future collections. Join us and leave your mark on AKAL’s journey.",
        heading4: "Join a Community:",
        context4: "Meet your new tribe—style rebels who appreciate quality and authenticity. This isn’t just about fashion; it’s about belonging to something bigger.",
    }
}
