import React from "react";
// import ReactGA from "react-ga4";
import Home from "./pages/Home";
import { ToastContainer } from 'react-toastify';
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import './assets/css/style.css'
import ReferalPage from "./pages/ReferralPage";
import SignUpPage from "./pages/SignUpPage";
import InvitePage from "./pages/InvitePage";
import './assets/css/canvas_style.css'
import './assets/css/new_style.css'

// ReactGA.initialize("UA-166271836-1");

const App = () => {

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });
  // }, []);

  return (
    <div className="w-full">
      <ToastContainer />
      <Routes>
        {/* <Route path={'/'} element={<Layout />}> */}
        <Route path={'/'} element={<Home />}></Route>
        <Route path={'/referralpage'} element={<ReferalPage />}></Route>
        <Route path={'/signup'} element={<SignUpPage />}></Route>
        <Route path={'/invitepage'} element={<InvitePage />}></Route>
        {/* </Route> */}
        {/* <Route path={'*'} element={<Layout />}>
          <Route path="*" element={<PageNotFound />} />
        </Route> */}
      </Routes>
    </div>
  );
}

export default App;
