export const GET_SUIT_FABRICS = () => {
    return `{
        shop {
          name
        }
        collectionByHandle(handle:"prelaunch") {
          description
          id
          products(first: 6,sortKey: BEST_SELLING, reverse: true) {
            edges {
              node {
                id
                description
                availableForSale
                productType
                title
                tags
                images(first: 6) {
                  edges {
                    node {
                      id
                      src
                      altText
                    }
                  }
                }
                variants(first:6) {
                  edges {
                    node {
                      id
                      image {
                        id
                        src
                        altText
                      }
                      sku
                      priceV2 {
                        amount
                        currencyCode
                      }
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
      
        `
  }