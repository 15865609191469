import React from "react";

const ButtonDesign = ({ value, className, arrowPath, arrow, targetdata, action, imgRotate, handleClick, dismiss }) => {
    return (
        <React.Fragment>
            <div className="reserve-btn">
                <button className={`${arrow ? 'btn-align btn-customize' : 'btn-align'} H-cb text-nowrap ${className}`} data-bs-toggle={action} data-bs-dismiss={dismiss} onClick={handleClick} data-bs-target={targetdata}>{value}
                    <span className={`${arrow ? 'd-inline-block' : 'd-none'}`}><img className={`${imgRotate} arrow-width ms-3`} src={arrowPath} alt="arrow" /></span>
                </button>
            </div>
        </React.Fragment>
    )
}

export default ButtonDesign;