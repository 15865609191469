import React, { useState, useEffect, useMemo } from "react";
import { contents } from '../../globals/app-constants'
export const Timer = (fixHeader) => {
    // const targetTime = new Date(2024, 3, 29, 18, 0, 0);
    const targetTime = useMemo(() => {
        return new Date(2024, 7, 11, 24, 0, 0);;
    }, []);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetTime));
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(targetTime));
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft, targetTime]);
    function calculateTimeLeft(targetTime) {
        const currentTime = new Date();
        const difference = targetTime - currentTime;

        if (difference < 0) {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
            };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
        const minutes = Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((difference / 1000) % 60).toString().padStart(2, '0');

        return {
            days,
            hours,
            minutes,
            seconds,
        };

    }

    return (
        <div className={`d-flex justify-content-center gap-2 gap-lg-4 ${fixHeader.fixHeader ? 'text-dark' : 'text-white'}`}>
            <div className="text-center d-flex flex-column">
                <p className={`fs-44 H-cb text-nowrap`}>{timeLeft?.days} :</p>
                <p className={`timer-text H-l d-flex justify-content-center`}><span>{contents.Timer.D}</span><span className="d-none d-sm-block">{contents.Timer.Days}</span></p>
            </div>
            <div className="text-center">
                <p className={`fs-44 H-cb text-nowrap`}>{timeLeft?.hours} :</p>
                <p className="timer-text H-l d-flex justify-content-center"><span>{contents.Timer.H}</span><span className="d-none d-sm-block">{contents.Timer.Hours}</span></p>
            </div>
            <div className="text-center">
                <p className={`fs-44 H-cb text-nowrap`}>{timeLeft?.minutes} :</p>
                <p className="timer-text H-l d-flex justify-content-center"><span>{contents.Timer.M}</span><span className="d-none d-sm-block">{contents.Timer.Mints}</span></p>
            </div>
            <div className="text-center">
                <p className={`fs-44 H-cb text-nowrap`}>{timeLeft?.seconds}</p>
                <p className="timer-text H-l d-flex justify-content-center"><span>{contents.Timer.S}</span><span className="d-none d-sm-block">{contents.Timer.Secs}</span></p>
            </div>
        </div>
    )
}