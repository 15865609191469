import { VIRAL_LOOP_URL } from "../utils";

const SignUpPage = () => {

    return (
        <form-widget mode='popup' ucid={VIRAL_LOOP_URL}></form-widget>

    )
}

export default SignUpPage;